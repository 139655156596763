<template>
  <RevCardCarousel
    :next-alternative-text="i18n(translations.alternativeNext)"
    :prev-alternative-text="i18n(translations.alternativePrevious)"
    :subtitle
    :title
  >
    <div v-for="(review, index) in reviews" :key="review.id" class="h-full">
      <UserCommentModal :pictures="review.pictures" :review>
        <template #trigger="{ open }">
          <RevCard
            class="h-full cursor-pointer hover:drop-shadow-xl"
            data-test="review-card"
            @click="() => handleReviewCardClick(open, { index })"
          >
            <div class="relative h-400">
              <RevIllustration
                alt=""
                class="rounded-t-lg h-full w-full object-cover"
                data-test="review-card-picture"
                :height="400"
                :src="review.pictures[0]"
                :width="256"
              />

              <div
                class="absolute bottom-0 left-0 right-0 top-0"
                :class="styles.darkOverlay"
              ></div>

              <RevTag
                class="absolute left-16 top-16"
                :label="`${review.customer.firstName} ${review.customer.lastName}`"
                variant="secondary"
              />

              <div
                class="mood-inverse absolute bottom-16 left-16 right-16 flex flex-col gap-16"
              >
                <div class="text-static-default-hi body-2 line-clamp-4">
                  {{ review.comment }}
                </div>

                <RevRating :score="review.averageRate" size="extra-small" />
              </div>
            </div>

            <div class="flex grow gap-16 p-16">
              <RevIllustration
                alt=""
                class="h-52 w-52"
                :height="52"
                sizes="52px"
                :src="review.product.imageUrl"
                :width="52"
              />

              <div class="text-static-default-hi caption">
                {{ review.product.title }}
              </div>
            </div>
          </RevCard>
        </template>
      </UserCommentModal>
    </div>
  </RevCardCarousel>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, useCssModule } from 'vue'

import type { Link } from '@backmarket/http-api'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import type { ReviewCustomer } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import UserCommentModal from '@backmarket/nuxt-layer-reviews/review-display/UserCommentModal.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevIllustration } from '@ds/components/Illustration'
import { closeModal } from '@ds/components/ModalBase'
import { RevRating } from '@ds/components/Rating'
import { RevTag } from '@ds/components/Tag'

import translations from './Reviews.translations'

export type Review = {
  product: {
    title: string
    link: Link
    imageUrl: string
  }
  customer: ReviewCustomer
  pictures: string[]
  id: string
  comment: string
  averageRate: number
  countryCode: string
  languageCode: string
  reviewDate: string
  purchaseDate: string
}
const props = defineProps<{
  title: string
  subtitle?: string
  reviews: Review[]
  tracking?: Tracking
}>()

const i18n = useI18n()
const styles = useCssModule()

const { trackClick } = useTracking()

function handleReviewCardClick(
  openModal: () => void,
  { index }: { index: number },
) {
  openModal()
  trackClick({
    ...props.tracking,
    position: index + 1,
  })
}

onBeforeUnmount(() => closeModal())
</script>

<style module>
.darkOverlay {
  background: linear-gradient(180deg, rgba(29, 32, 36, 0) 49.5%, #1d2024 80.5%);
}
</style>
